section {
	display: flex;
	flex-direction: row-reverse;
	height: 100%;
	width: calc(100% - 220px);
	align-items: center;
	justify-content: space-between;
	animation: fadeIn var(--transition-duration);
	z-index: 1;
}

.section-item {
	display: flex;
	flex-direction: column;
	cursor: default;
}

.section-item-title {
	font-weight: bold;
	font-size: 24px;
	line-height: 1;
	margin-bottom: 5px;
	transition: all var(--transition-duration) ease;
}

.section-item-description {
	font-weight: normal;
	font-size: 16px;
	transition: all var(--transition-duration) ease;
}

.section-main-content {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: auto;
	align-items: flex-end;
	justify-content: center;
	text-align: right;
	gap: 24px;
}

@media only screen and (max-width: 830px) {
	section {
		flex-direction: column;
		height: auto;
		width: 100%;
		margin: auto;
	}

	.section-main-content {
		text-align: center;
	}

	.section-item > * {
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
}

@media only screen and (max-width: 130px) {
	section {
		flex-direction: column;
		height: auto;
		width: 100%;
		margin: auto;
	}

	.section-item-title {
		font-size: 5.5vw;
	}

	.section-item-title {
		font-size: 5.5vw;
	}

	.section-item-description {
		font-size: 3.5vw;
	}

	.section-main-content {
		text-align: center;
		align-items: center;
		height: auto;
		width: 100%;
	}
}

@media only screen and (max-width: 830px) and (max-height: 750px) {
	.section-item-description {
		font-size: 14px;
	}
}

@media only screen and (max-width: 830px) and (max-height: 730px) {
	.section-item-title {
		font-size: 3vh;
	}

	.section-item-description {
		font-size: 2vh;
	}
}
