@font-face {
	font-family: "Mabry";
	src: url("../fonts/Mabry/MabryPro-Regular.woff") format("woff");
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: "Mabry";
	src: url("../fonts/Mabry/MabryPro-Medium.woff") format("woff");
	font-weight: 300;
	font-display: swap;
}

@font-face {
	font-family: "Mabry";
	src: url("../fonts/Mabry/MabryPro-Bold.woff") format("woff");
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: "Mabry";
	src: url("../fonts/Mabry/MabryPro-Black.woff") format("woff");
	font-weight: 900;
	font-display: swap;
}

:root {
	--black-color: #121212;
	--white-color: #ededed;
	--success-color: #00c51b;
	--error-color: #c5001b;
	--page-padding: 24px;
	--page-border-width: 2px;
	--transition-duration: 1s;
	--primary-color: var(--black-color);
	--secondary-color: var(--white-color);
}

body {
	margin: 0;
	font-family: "Mabry", sans-serif;
	display: flex;
	width: calc(100dvw - 2 * var(--page-padding));
	height: calc(100dvh - 2 * var(--page-padding));
	margin: calc(var(--page-padding) - var(--page-border-width)) auto 0;
	overflow: hidden;
	background-color: var(--secondary-color);
	color: var(--primary-color);
	min-height: 650px;
}

@media (hover: none) {
	body {
		user-select: none;
	}
}

.light-mode {
	border: var(--page-border-width) solid var(--primary-color);
}

button,
a {
	text-decoration: none;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	margin: inherit;
	padding: inherit;
	box-sizing: inherit;
	background-color: inherit;
	outline: none;
	border: inherit;
	font-weight: inherit;
}

h1,
h2,
h3 {
	all: unset;
}

a {
	cursor: pointer;
}

button:focus,
a:focus {
	outline: 1px solid var(--primary-color);
}

::selection {
	background: var(--primary-color);
	color: var(--secondary-color);
}

#root {
	display: flex;
	flex-direction: column;
	width: calc(100% - var(--page-padding) * 2);
	height: calc(100% - var(--page-padding));
	padding: var(--page-padding);
	gap: var(--page-padding);
}

main {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: space-between;
}

.visible {
	animation: fadeIn var(--transition-duration);
}
.hidden {
	animation: fadeOut 0.1s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@media only screen and (max-width: 830px) {
	body {
		min-height: 100vh;
		height: 750px;
	}

	#root {
		gap: var(--page-padding);
	}
	main {
		flex-direction: column;
		height: auto;
		gap: calc(2 * var(--page-padding));
		align-items: center;
	}
}

@media only screen and (max-height: 730px) {
	main {
		gap: var(--page-padding);
	}
}

@media only screen and (max-width: 830px) and (max-height: 500px) {
	.ocean {
		display: none;
	}

	#section-home .section-item {
		font-size: 3vh;
		line-height: normal;
	}
}

@media only screen and (min-width: 830px) and (max-height: 680px) {
	.ocean,
	main {
		display: none;
	}
}

@media only screen and (max-height: 350px) {
	main {
		display: none;
	}
}

.border-mask {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
	width: calc(100dvw - 2 * var(--page-padding));
	height: calc(100dvh - 2 * var(--page-padding));
	outline: var(--page-padding) solid var(--secondary-color);
	border: var(--page-border-width) solid var(--primary-color);
	background-color: transparent;
}
