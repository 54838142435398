header {
	display: flex;
	width: 100%;
	height: 67px;
	align-content: flex-start;
	z-index: 1;
}

#title-container {
	display: flex;
	font-size: 96px;
	align-items: center;
	font-weight: 900;
	height: 63px;
	line-height: 63px;
	margin-left: -7px;
	margin-right: auto;
}

#title-container > h1 {
	transition: calc(var(--transition-duration));
	cursor: pointer;
}

#my-name-container {
	position: absolute;
	left: calc(2 * var(--page-padding) - 7px);
}

#icons-container {
	display: flex;
	margin-left: auto;
	font-size: 24px;
	gap: 18px;
}

#icons-container > * {
	display: flex;
	cursor: pointer;
	height: 24px;
}

#icons-container > *:first-child {
	margin-right: 24px;
}

#light-mode-icon:first-child {
	transition: color 0.5s ease-in-out;
}

@media only screen and (max-width: 1150px) {
	#title-container {
		line-height: 7.5vw;
		font-size: 7.5vw;
	}
}

@media only screen and (max-width: 830px) {
	header {
		flex-direction: column-reverse;
		height: auto;
		gap: calc(var(--page-padding) / 2);
	}
	#title-container {
		margin: 0 auto;
		text-align: center;
		height: auto;
	}
	#my-name-container {
		white-space: nowrap;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	#icons-container {
		margin: 0 auto;
	}
	#icons-container > *:first-child {
		margin-right: 0px;
	}
}
