nav {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: auto;
	align-items: flex-start;
	justify-content: center;
	gap: 12px;
}

.nav-item {
	display: flex;
	width: auto;
	background-color: var(--secondary-color);
	color: var(--primary-color);
	border: 2px solid var(--primary-color);
	height: 21px;
	padding: 5px 10px;
	font-weight: 600;
	align-items: center;
	transform: skew(-22deg);
	cursor: pointer;
	backface-visibility: initial !important;
	-webkit-backface-visibility: initial !important;
}

.nav-item > span {
	width: auto;
	transform: skew(22deg);
	white-space: nowrap;
}

.nav-item:hover,
.nav-item-selected {
	background-color: var(--primary-color);
	color: var(--secondary-color);
}

@media only screen and (max-width: 830px) {
	nav {
		flex-direction: row;
		height: auto;
		width: 100%;
		flex-wrap: wrap;
	}
}

@media only screen and (max-width: 830px) and (max-height: 800px) {
	.nav-item {
		font-size: 14px;
	}
}

@media only screen and (max-width: 830px) and (max-height: 730px) {
	.nav-item {
		font-size: 2vh;
	}
}

@media only screen and (max-width: 580px) {
	nav {
		max-width: 380px;
		gap: 6px 12px;
	}
}

@media only screen and (max-width: 830px) and (max-height: 600px) {
	nav {
		display: none;
	}
}
