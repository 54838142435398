.contact-form-field {
	outline: none;
	border-bottom: 1px solid var(--primary-color);
	min-width: 200px;
	min-height: 22px;
	height: auto;
	transition: height 0.3s ease;
	max-width: calc(100vw - 350px);
	transition: all var(--transition-duration) ease;
	cursor: text;
}

#contact-form-button-container {
	flex-direction: row;
	align-items: center;
	gap: 20px;
	margin-right: 8px;
}

#contact-form-button-container > div {
	margin-bottom: 0px;
}

.style-error,
.style-error > * {
	color: var(--error-color);
}

.style-error .section-item-description {
	border-bottom: 1px solid var(--error-color);
}
.style-success,
.style-success > * {
	color: var(--success-color);
}

.style-success .section-item-description {
	border-bottom: 1px solid var(--success-color);
}

@media only screen and (max-width: 830px) {
	.contact-form-field {
		min-height: 3.5vw;
		width: 100%;
		margin: 0 auto;
		max-width: 400px;
	}
	.section-item {
		width: 100%;
	}
	#contact-form-button-container {
		flex-direction: column-reverse;
		margin-right: 0px;
	}
}

#contact-form-field-message {
	overflow: hidden !important;
	max-height: 150px !important;
}

/* TODO outline

language
w3

h1, buttons, etc
keyboard nav
bookmarks */
