.ocean {
	height: 2px;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.wave {
	-webkit-mask: url("../images/wave.svg") repeat-x;
	mask: url("../images/wave.svg") repeat-x;
	position: fixed;
	bottom: -55px;
	width: 6400px;
	height: 198px;
	animation: wave 12s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite,
		swell 8s ease -1.25s infinite;
	z-index: -1;
	transform: translate3d(0, 0, 0);
	background-color: var(--primary-color);
	transition: all var(--transition-duration) ease;
}

@keyframes wave {
	0% {
		margin-left: 0;
	}
	100% {
		margin-left: -1600px;
	}
}
@keyframes swell {
	0%,
	100% {
		transform: translate3d(0, -40px, 0);
	}
	50% {
		transform: translate3d(0, -10px, 0);
	}
}

@media only screen and (max-height: 800px) {
	.wave {
		bottom: -20vh;
	}
}

@media only screen and (max-width: 405px) {
	#footer-mask-center {
		width: calc(100% - 2 * var(--page-padding) + 12px);
	}
}
