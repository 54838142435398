#projects-list > div {
	cursor: pointer;
}

#selected-project-thumbnail {
	display: flex;
	margin: 0 auto;
	max-height: 300px;
	max-width: 400px;
}

#selected-project-thumbnail > img {
	height: 100%;
	width: 100%;
	border: 1px solid var(--black-color);
}

@media only screen and (max-width: 830px) {
	#selected-project-thumbnail {
		display: none;
	}
}

@media only screen and (max-width: 1150px) {
	#projects-list .section-item-title {
		max-width: 250px;
	}
}

#selected-project-thumbnail {
	height: 250px;
}

.section-item:focus {
	text-decoration: underline;
}
